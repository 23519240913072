import React from 'react'
import PmforFoundersHero from '../PagesSub/CoachingSub/PmforFounders/PmforFoundersHero/PmforFoundersHero'
import PmforFoundersMain from '../PagesSub/CoachingSub/PmforFounders/PmforFoundersMain/PmforFoundersMain'

const Productmanagementforfounders = () => {
  return (
    <>
      <PmforFoundersHero />
      <PmforFoundersMain />
    </>
  )
}

export default Productmanagementforfounders