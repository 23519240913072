import React from 'react'
import PmFoundersHero from '../PagesSub/TrainingSub/PmFounders/PmFoundersHero/PmFoundersHero'
import PmFoundersMain from '../PagesSub/TrainingSub/PmFounders/PmFoundersMain/PmFoundersMain'

const Pmforfounders = () => {
  return (
   <>
    <PmFoundersHero />
    <PmFoundersMain />
   </>
  )
}

export default Pmforfounders