import React from 'react'
import PmforPmHero from '../PagesSub/CoachingSub/PmforPm/PmforPmHero/PmforPmHero'
import PmforPmMain from '../PagesSub/CoachingSub/PmforPm/PmforPmMain/PmforPmMain'

const Productmanagementforpm = () => {
  return (
    <>
      <PmforPmHero />
      <PmforPmMain />
    </>
  )
}

export default Productmanagementforpm