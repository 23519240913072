import React from 'react'
import StratHero from '../PagesSub/ConsultingSub/ProductStrat/StratHero/StratHero'
import StratServices from '../PagesSub/ConsultingSub/ProductStrat/StratServices/StratServices'

const Productstrategy = () => {
    return (
        <>
        <StratHero />
        <StratServices />
        </>
      )
}

export default Productstrategy