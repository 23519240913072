import React from 'react'
import CustomerDevelopMain from '../PagesSub/ConsultingSub/CustomerDevlop/CustomerDevelopMain/CustomerDevelopMain'
import CustomerDevelopHero from '../PagesSub/ConsultingSub/CustomerDevlop/CustomerDevelopHero/CustomerDevelopHero'

const Customerdevelopment = () => {
  return (
   <>
   <CustomerDevelopHero/>
    <CustomerDevelopMain />
   </>
  )
}

export default Customerdevelopment