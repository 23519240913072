import React from 'react'
import AdvancedPmHero from '../PagesSub/TrainingSub/AdvancedPm/AdvancedPmHero/AdvancedPmHero'
import AdvancedPmMain from '../PagesSub/TrainingSub/AdvancedPm/AdvancedPmMain/AdvancedPmMain'

const Advancedpm = () => {
  return (
   <>
    <AdvancedPmHero/>
    <AdvancedPmMain />
   </>
  )
}

export default Advancedpm