import React from 'react'
import IntroPmHero from '../PagesSub/TrainingSub/IntroPm/IntroPmHero/IntroPmHero'
import IntroPmMain from '../PagesSub/TrainingSub/IntroPm/IntroPmMain/IntroPmMain'

const Introductiontopm = () => {
  return (
    <>
        <IntroPmHero />
        <IntroPmMain />
    </>
  )
}

export default Introductiontopm