import React from 'react'
import ProjectSavingHero from '../PagesSub/ConsultingSub/ProjectSaving/ProjectSavingHero/ProjectSavingHero'
import ProjectSaving from '../PagesSub/ConsultingSub/ProjectSaving/ProjectSavingMain/ProjectSaving'

const Projectsaving = () => {
  return (
    <>
        <ProjectSavingHero/>
        <ProjectSaving />
    </>
  )
}

export default Projectsaving