import React from 'react'
import GroupCoachinHero from '../PagesSub/CoachingSub/GroupCoachin/GroupCoachinHero/GroupCoachinHero'
import GroupCoachinMain from '../PagesSub/CoachingSub/GroupCoachin/GroupCoachinMain/GroupCoachinMain'

const Groupcoaching = () => {
  return (
    <>
      <GroupCoachinHero/>
      <GroupCoachinMain />
    </>
  )
}

export default Groupcoaching